import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { MainHeader } from "../components/sections"
import SEO from "../components/seo"
import { CatchyTitle } from "../components/typography"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact us" />
    <MainHeader />
    <AddressSection>
      <MelbourneSection>
        <Top>
          <CatchyTitle>Melbourne </CatchyTitle>
        </Top>
        <Paragraph>
          Level 1, 80-82 Bell Street,
          <br /> Heidelberg Heights,
          <br /> VIC 3081
        </Paragraph>
        <MapFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.543046402875!2d145.05104071584304!3d-37.75386313839225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64681665f1f39%3A0x89253d1d05cb469b!2sOffice%3A%20Level%201%2C%2080%2F82%20Bell%20St%2C%20Heidelberg%20Heights%20VIC%203081!5e0!3m2!1sen!2sau!4v1600132155299!5m2!1sen!2sau"></MapFrame>
      </MelbourneSection>
      <SydneySection>
        <Top>
          <CatchyTitle>Sydney </CatchyTitle>
        </Top>
        <Paragraph>
          Suite 3, 55 Phillip Street,
          <br /> Parramatta,
          <br /> NSW 2150
        </Paragraph>
        <MapFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.984052417801!2d151.00283201574385!3d-33.81272462358303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a31ebfa41f03%3A0xa2cdd8379050408b!2s3%2F55%20Phillip%20St%2C%20Parramatta%20NSW%202150!5e0!3m2!1sen!2sau!4v1600133722078!5m2!1sen!2sau"></MapFrame>
      </SydneySection>
    </AddressSection>
  </Layout>
)
export default ContactUs

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.black};
`
const AddressSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`
const MelbourneSection = styled.div`
  padding: 3rem 3rem;
`
const SydneySection = styled.div``

const MapFrame = styled.iframe`
  min-height: 40rem;
  width: 40rem;
  padding: 3rem 0;
  border-radius: 1rem;
  @media only screen and (max-width: 37.5em) {
    min-height: 40rem;
    width: 95%;
    padding: 2rem 0;
  }
`
const Top = styled.div`
  padding: 1rem 0;
`
